import React from "react"
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Row, Col, Button } from "react-bootstrap"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { faFileAlt } from "@fortawesome/free-regular-svg-icons"
import { addToCart } from "../components/actions/cartActions"
import { connect } from "react-redux"

class ProductDetails extends React.Component {
    handleClick = (id, name, price, image, databaseId)=>{
        if (this.state.disabled) {
            return
        }
        this.setState({disabled: true})
        this.props.addToCart(id, name, price, image, databaseId)
        setTimeout(() => {
            this.setState({disabled: false})
            this.setState({success: true})
            setTimeout(() => {
                this.setState({success: false})
            }, 1000)
        }, 300)
    }
    state = {
        disabled: false,
        success: false
    }
    render() {
        
        return (
            <>
                <p><small><Link className={`text-muted text-decoration-none`} to="/"><FontAwesomeIcon className={`me-1`} icon={faHome} /></Link> <span className={`text-muted text-decoration-none`}>/</span> <Link className={`text-muted text-decoration-none`} to={`/shop/${this.props.productCategories.nodes[0].name.toLowerCase()}`}>{this.props.productCategories.nodes[0].name}</Link> <span className={`text-muted text-decoration-none`}>/</span> {this.props.name}</small></p>
                <Row className={`my-5`}>
                    <Col>
                        <GatsbyImage image={this.props.productImage} alt="Testing image" />
                    </Col>
                    <Col>
                        <Row>
                            <Col>
                                <p className={`mb-0`}><small><strong>Standard Shipping</strong></small></p>
                                <p><small>Free with 3-6 business days</small></p>
                            </Col>
                            <Col>
                                <p className={`mb-0`}><small><strong>Express Delivery</strong></small></p>
                                <p><small>$35.00 available</small></p>
                            </Col>
                        </Row>
                        {this.props.salePrice &&
                            <Row>
                                <Col>
                                    <Button variant="danger" className={`rounded-pill px-3 text-uppercase fw-bold`}><small>Sale</small></Button>
                                </Col>
                            </Row>
                        }
                        <Row>
                            <Col>
                                <h2 className={`fw-light mt-3`}>{this.props.name}</h2>
                                {this.props.salePrice &&
                                    <>
                                        <span className={`text-danger fs-4`}>{this.props.salePrice}</span> <span className={`text-muted mx-4 fw-light fs-4`}><s>{this.props.regularPrice}</s></span>
                                    </>
                                }
                                {!this.props.salePrice &&
                                    <>
                                        <span className={`fs-4`}>{this.props.price}</span>
                                    </>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button disabled={this.state.disabled} onClick={() => { this.handleClick(this.props.id, this.props.name, Number(this.props.price.replace(/[^0-9-\.]+/g, "")), this.props.productImage, this.props.databaseId) }} variant={this.state.success ? 'success' : 'warning'} className={`rounded-pill px-5 text-uppercase fw-bold mt-5`}>{this.state.disabled ? 'Adding...' : this.state.success ? 'Succeeded' : 'Add to Cart'}</Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Row className={`my-5 justify-content-around`}>
                    <Col md={8}>
                        <p className={`text-center my-5`}><strong>Details and product description</strong></p>
                        <div dangerouslySetInnerHTML={{ __html: this.props.description }} />
                    </Col>
                </Row>
            </>
        )
    }
}

const mapStateToProps = (state)=>{
    return {
      items: state.items
    }
  }

const mapDispatchToProps= (dispatch)=>{   
    return{
        addToCart: (id, name, price, image, databaseId)=>{dispatch(addToCart(id, name, price, image, databaseId))}
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(ProductDetails)