import { ADD_TO_CART, REMOVE_ITEM } from './action-types/cart-actions'
import { CLEAR_CART } from './action-types/cart-actions'

export const addToCart = (id, name, price, image, databaseId) => {
    return {
        type: ADD_TO_CART,
        id,
        name,
        price,
        image,
        databaseId
    }
}

export const removeItem = (id) => {
    return {
        type: REMOVE_ITEM,
        id
    }
}

export const clearCart = () => {
    return {
        type: CLEAR_CART
    }
}