import React from "react"
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { Container, Row, Col } from "react-bootstrap"
import ProductSlider from "../components/product-slider"
import Signup from "../components/newsletter-signup"
import ProductDetails from "../components/product-details"

export const query = graphql`
    query($id: String) {
        placeholder: file(relativePath: {eq: "placeholder.png"}) {
            childImageSharp {
              gatsbyImageData(
                width: 600
                placeholder:BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        wpSimpleProduct(id: { eq: $id }) {
            id
            databaseId
            name
            description
            price
            salePrice
            regularPrice
            related {
                nodes {
                    ... on WpSimpleProduct {
                    image {
                        localFile {
                        childImageSharp {
                            gatsbyImageData(width: 450, placeholder: BLURRED, formats: AUTO)
                        }
                        }
                    }
                    name
                    slug
                    price
                    salePrice
                    regularPrice
                    }
                }
            }
            allPaBrand {
                nodes {
                    name
                }
            }
            productCategories {
                nodes {
                    name
                }
            }
            image {
                localFile {
                    childImageSharp {
                    gatsbyImageData(width: 450, placeholder: BLURRED, formats: AUTO)
                    }
                }
            }
        }
        promo2: file(relativePath: {eq: "promo-2.jpeg"}) {
            childImageSharp {
          gatsbyImageData(
            width: 600
            placeholder:BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }`

const WpProduct = ({ data: {
    wpSimpleProduct: { name, productCategories, image, description, related, price, salePrice, regularPrice, id, databaseId },
    promo2,
    placeholder
},}) => {
    let productImage
    if (image) {
        productImage = getImage(image.localFile)
    } else {
        productImage = getImage(placeholder)
    }
    const relatedProducts = related.nodes
    
    return (
        <Layout>
            <Container>
                <ProductDetails productCategories={productCategories} name={name} productImage={productImage} salePrice={salePrice} regularPrice={regularPrice} price={price} id={id} description={description} databaseId={databaseId}  />
                <Row className={`my-5`}>
                <Col>
                <ProductSlider widgetTitle={`Selected just for you`} products={relatedProducts} placeholder={placeholder} />
                </Col>
                </Row>

                <Row className={`my-5`}>
                    <Signup background={promo2} />
                </Row>
            </Container>
        </Layout>
    )
}

export default WpProduct